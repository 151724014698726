<template>
    <div>
        <b-modal id="modal-update-banner" cancel-variant="outline-secondary" :ok-title="$t('Update')"
            :cancel-title="$t('Close')" ok-variant="primary" centered :title="$t('Update banner')" @ok="confirmUpdate"
            @hidden="resetModal()">
            <!-- form -->
            <validation-observer ref="addBannerModal">
                <b-form method="POST" class="auth-reset-password-form mt-2" @submit.prevent="validationForm">
                    <!-- Field: Name -->
                    <!-- <b-form-group label-for="title">
                        <label class="mb-1">{{ $t('Title') }}</label>
                        <validation-provider #default="{ errors }" name="title">
                            <b-form-input id="title" v-model="banner.title"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group> -->

                    <b-form-group label-for="type">
                        <label class="mb-1">{{ $t('Type') }}</label>
                        <validation-provider #default="{ errors }" name="type">
                            <v-select v-model="banner.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="typeOptions" :reduce="val => val.value" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label-for="platform">
                        <label class="mb-1">{{ $t('Platform') }}</label>
                        <validation-provider #default="{ errors }" name="platform">
                            <v-select v-model="banner.platform" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="platformOptions" :reduce="val => val.value" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group
						label-for="link"
					>
						<label class="mb-1">{{ $t('Link') }}</label>
						<validation-provider
							#default="{ errors }"
							name="link"
						>
							<b-form-input
								id="link"
								v-model="banner.link"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

                    <b-form-group label-for="status">
                        <label class="mb-1">{{ $t('Status') }}</label>
                        <validation-provider #default="{ errors }" name="status">
                            <v-select v-model="banner.status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="statusOptions" :reduce="val => val.value" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- Field: Link -->
                    <!-- <b-form-group label-for="link">
                        <label class="mb-1">{{ $t('Link') }}</label>
                        <validation-provider #default="{ errors }" name="link">
                            <b-form-input id="link" v-model="banner.link"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group> -->
                </b-form>
            </validation-observer>

        </b-modal>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
    BLink, BRow, BCol, BTabs, BTab, BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default {
    components: {
        BLink, BRow, BCol, BTabs, BTab, BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        vSelect,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    props: {
        bannerDetail: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            banner: {
                id: '',
                status: '',
                type: '',
                platform: '',
                link: ''
            },
            typeOptions: [
                {
                    label: 'Home',
                    value: 1
                },
                {
                    label: 'Promotion',
                    value: 2
                }
            ],
            platformOptions: [
                {
                    label: 'Desktop',
                    value: 1,
                },
                {
                    label: 'Mobile',
                    value: 2,
                }
            ]
        }
    },
    watch: {
        bannerDetail: {
            handler(newVal) {
                if (newVal) {
                    this.banner = {
                        id: newVal.id,
                        type: newVal.type,
                        status: newVal.status,
                        platform: newVal.platform,
                        link: newVal.link
                    };
                }
            },
            deep: true,
        },
    },
    methods: {
        validationForm() {
            this.$refs.addBannerModal.validate().then(success => {
                if (success) {
                    this.updateBanner(this.banner)
                }
            })
        },
        async updateBanner(bannerData) {
            await this.$store.dispatch('frontend/updateBanner', bannerData)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.code == '00') {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message || 'success',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            })
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.$bvModal.hide('modal-update-banner')
                                    this.resetModal()
                                }, '1000')
                            })
                            this.$emit('refetch-data')
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                },
                            })
                        }
                    }
                })
        },
        resetModal() {
            this.banner = {
                id: '',
                status: '',
                type: '',
                platform: '',
                link: ''
            };
            this.$emit("update:bannerDetail", null);
        },
        confirmUpdate(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.validationForm()
        }
    },
    setup() {
        const statusOptions = [
            { label: "Active", value: 1 },
            { label: "Inactive", value: 2 },
        ];

        return {
            statusOptions
        }
    },
}
</script>