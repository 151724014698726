var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-update-banner","cancel-variant":"outline-secondary","ok-title":_vm.$t('Update'),"cancel-title":_vm.$t('Close'),"ok-variant":"primary","centered":"","title":_vm.$t('Update banner')},on:{"ok":_vm.confirmUpdate,"hidden":function($event){return _vm.resetModal()}}},[_c('validation-observer',{ref:"addBannerModal"},[_c('b-form',{staticClass:"auth-reset-password-form mt-2",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"type"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Type')))]),_c('validation-provider',{attrs:{"name":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOptions,"reduce":function (val) { return val.value; }},model:{value:(_vm.banner.type),callback:function ($$v) {_vm.$set(_vm.banner, "type", $$v)},expression:"banner.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"platform"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Platform')))]),_c('validation-provider',{attrs:{"name":"platform"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.platformOptions,"reduce":function (val) { return val.value; }},model:{value:(_vm.banner.platform),callback:function ($$v) {_vm.$set(_vm.banner, "platform", $$v)},expression:"banner.platform"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"link"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Link')))]),_c('validation-provider',{attrs:{"name":"link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"link","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.banner.link),callback:function ($$v) {_vm.$set(_vm.banner, "link", $$v)},expression:"banner.link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"status"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Status')))]),_c('validation-provider',{attrs:{"name":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; }},model:{value:(_vm.banner.status),callback:function ($$v) {_vm.$set(_vm.banner, "status", $$v)},expression:"banner.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }